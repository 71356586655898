@keyframes down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes curtain {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes blur {
  0% {
    backdrop-filter: blur(0px);
  }
  
  100% {
    backdrop-filter: blur(9px);
  }
}